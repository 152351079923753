import { useSelector } from 'react-redux';
import './GamesPage.scss';
import { formatNumber } from '../../helpers';

const GamesPage = (props) => {

  const betsData = useSelector((state) => state.app.betsData);
  const winsData = useSelector((state) => state.app.winsData);
  const freespinsData = useSelector((state) => state.app.freespinsData);
  const games = useSelector((state) => state.app.games);
  
  return (
    <div className="GamesPage">
      <table>
        <thead>
          <tr>
            <th>Game ID</th>
            <th>Name</th>
            <th>Total Bets</th>
            <th>Total Wins</th>
            <th>Revenue</th>
          </tr>
        </thead>
        <tbody>
          {
            games.map((game, i) => {
              let totalBets = betsData.filter((b) => b.game_id === game.key).reduce((p, c) => p + c.amount, 0);
              totalBets += freespinsData.filter((f) => f.game_id === game.key && f.bet_id === null).reduce((p, c) => p + (c.bet_amount * 100), 0);
              const totalWins = winsData.filter((b) => b.game_id === game.key).reduce((p, c) => p + (c.amount_after_gamble !== null ? c.amount_after_gamble : c.amount), 0);

              return (
                <tr
                  key={i}
                >
                  <td>{game.id}</td>
                  <td>{game.key}</td>
                  <td>{formatNumber(totalBets)}&nbsp;$</td>
                  <td>{formatNumber(totalWins)}&nbsp;$</td>
                  <td>{formatNumber(totalBets - totalWins)}&nbsp;$</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default GamesPage;
