const formatNumber = (number) => {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export {
  formatNumber,
};

