import './Dashboard.scss';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../helpers';

const Dashboard = (props) => {
  const [topGames, setTopGames] = useState([]);
  const [topPartners, setTopPartners] = useState([]);

  const games = useSelector((state) => state.app.games);
  const partners = useSelector((state) => state.app.partners);
  const betsData = useSelector((state) => state.app.betsData);
  const winsData = useSelector((state) => state.app.winsData);
  const freespinsData = useSelector((state) => state.app.freespinsData);

  useEffect(() => {
    if (games.length && betsData.length) {
      const tg = [];

      games.forEach((game) => {
        let totalBets = betsData.filter((bet) => bet.game_id === game.key).reduce((p, c) => p + c.amount, 0);
        totalBets += freespinsData.filter((r) => r.game_id === game.key && r.bet_id === null).reduce((p, c) => p + (c.bet_amount * 100), 0);

        tg.push({
          ...game,
          totalBets,
        });
      });

      tg.sort((a, b) => {
        if (a.totalBets > b.totalBets) {
          return -1;
        } else {
          return 1;
        }
      });

      setTopGames(tg);
    }
  }, [games, betsData]);

  useEffect(() => {
    if (partners.length && betsData.length) {
      const tp = [];

      partners.forEach((partner) => {
        let totalBets = betsData.filter((bet) => bet.partner_id === partner.id).reduce((p, c) => p + c.amount, 0);
        totalBets += freespinsData.filter((r) => r.partner_id === partner.id && r.bet_id === null).reduce((p, c) => p + (c.bet_amount * 100), 0);

        tp.push({
          ...partner,
          totalBets,
        });
      });

      tp.sort((a, b) => {
        if (a.totalBets > b.totalBets) {
          return -1;
        } else {
          return 1;
        }
      });

      setTopPartners(tp);
    }
  }, [partners, betsData]);

  let betsTotalNumber = betsData.reduce((p, c) => p + c.amount, 0);
  betsTotalNumber += freespinsData.filter((r) => r.bet_id === null).reduce((p, c) => p + (c.bet_amount * 100), 0);
  const betsTotal = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(betsTotalNumber);
  const winsTotalNumber = winsData.reduce((p, c) => p + (c.amount_after_gamble !== null ? c.amount_after_gamble : c.amount), 0);
  const winsTotal = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(winsTotalNumber);
  const revenue = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(betsTotalNumber - winsTotalNumber);

  return (
    <div className="Dashboard">
      <div className="content">
        <div className="tools"></div>

        <div className="totals">
          <div className="left">
            <div className="total-bets">
              <span className="label">Total number of bets</span>

              <span className="amount">
                {betsTotal} $
              </span>
            </div>

            <div className="total-wins">
              <span className="label">Total number of wins</span>

              <span className="amount">
                {winsTotal} $
              </span>
            </div>
          </div>

          <div className="right">
            <div className="total-revenue">
              <span className="label">Total revenue by games</span>

              <span className="amount">
                {revenue} $
              </span>
            </div>

            <div className="total-profit">
              <span className="label">Profit by partners</span>

              <span className="amount">
                - $
              </span>
            </div>
          </div>
        </div>

        <div className="ranking">
          <div className="top-games">
            <div className="header">Top games by bets</div>

            <div className="list">
              {
                topGames.slice(0, 5).map((game, i) => {
                  return (
                    <div
                      key={i}
                    >
                      <span className="name">{game.key}</span>
                      <span className="amount">{formatNumber(game.totalBets)}&nbsp;$</span>
                    </div>
                  );
                })
              }
            </div>
          </div>

          <div className="top-partners">
            <div className="header">Top betting partners</div>

            <div className="list">
              {
                topPartners.slice(0, 5).map((partner, i) => {
                  return (
                    <div
                      key={i}
                    >
                      <span className="name">{partner.name}</span>
                      <span className="amount">{formatNumber(partner.totalBets)}&nbsp;$</span>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
