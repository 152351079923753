import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import './overrides.scss';
import './classes.scss';
import Dashboard from './components/dashboard/Dashboard';
import Navbar from './components/navbar/Navbar';
import { useEffect } from 'react';
import request from './request';
import store from './store';
import appSlice from './store/app/appSlice';
import BetsPage from './components/bets-page/BetsPage';
import WinsPage from './components/wins-page/WinsPage';
import GamesPage from './components/games-page/GamesPage';
import PartnersPage from './components/partners-page/PartnersPage';

function App() {

  useEffect(() => {
    getGames().then(() => {
      getPartners().then(() => {
        getFreespinsData().then(() => {
          getGamblesData().then(() => {
            getBetsData();
            getWinsData();
          });
        });
      });
    });
  }, []);

  const getGames = () => {
    return request
      .get(`games`)
      .then((response) => {
        store.dispatch(appSlice.actions.setData({
          games: response.data,
        }));
      })
      .catch((err) => {

      });
  };

  const getPartners = () => {
    return request
      .get(`partners`)
      .then((response) => {
        store.dispatch(appSlice.actions.setData({
          partners: response.data,
        }));
      })
      .catch((err) => {

      });
  };

  const getBetsData = () => {
    return request
      .get(`bets-data`)
      .then((response) => {
        store.dispatch(appSlice.actions.setData({
          betsData: response.data,
        }));
      })
      .catch((err) => {

      });
  };

  const getWinsData = () => {
    return request
      .get(`wins-data`)
      .then((response) => {
        store.dispatch(appSlice.actions.setData({
          winsData: response.data,
        }));
      })
      .catch((err) => {

      });
  };

  const getFreespinsData = () => {
    return request
      .get(`freespins-data`)
      .then((response) => {
        store.dispatch(appSlice.actions.setData({
          freespinsData: response.data,
        }));
      })
      .catch((err) => {

      });
  };

  const getGamblesData = () => {
    return request
      .get(`gambles-data`)
      .then((response) => {
        store.dispatch(appSlice.actions.setData({
          gamblesData: response.data,
        }));
      })
      .catch((err) => {

      });
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar></Navbar>
        
        <main>
          <Routes>
            <Route path="/" element={<Dashboard></Dashboard>}></Route>
            <Route path="/bets" element={<BetsPage></BetsPage>}></Route>
            <Route path="/wins" element={<WinsPage></WinsPage>}></Route>
            <Route path="/games" element={<GamesPage></GamesPage>}></Route>
            <Route path="/partners" element={<PartnersPage></PartnersPage>}></Route>
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
