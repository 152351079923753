import { useEffect } from 'react';
import './BetsPage.scss';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../helpers';

const BetsPage = (props) => {

  const betsData = useSelector((state) => state.app.betsData);
  const partners = useSelector((state) => state.app.partners);
  const games = useSelector((state) => state.app.games);

  return (
    <div className="BetsPage">
      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Date</th>
            <th>Bet ID</th>
            <th>Partner</th>
            <th>Player ID</th>
            <th>Game</th>
            <th>Bet Amount</th>
          </tr>
        </thead>
        <tbody>
          {
            betsData.map((bet, i) => {
              const partner = partners.find((p) => p.id === bet.partner_id);
              const game = games.find((g) => g.key === bet.game_id);

              return (
                <tr
                  key={i}
                >
                  <td>{bet.time}</td>
                  <td>{bet.date}</td>
                  <td>{bet.id}</td>
                  <td>{partner && partner.name}</td>
                  <td>{bet.player_id}</td>
                  <td>{game.key}</td>
                  <td>{formatNumber(bet.amount)} {bet.currency}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default BetsPage;
