import { Link } from 'react-router-dom';
import './Navbar.scss';

const Navbar = (props) => {
  return (
    <div className="Navbar">
      <div className="options">
        <Link to="/">
          Dashboard
        </Link>

        <Link to="/bets">
          Bets
        </Link>

        <Link to="/wins">
          Wins
        </Link>

        <Link to="/games">
          Games
        </Link>

        <Link to="/partners">
          Partners
        </Link>

        <Link to="/settings">
          Settings
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
