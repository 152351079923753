import { useEffect } from 'react';
import './WinsPage.scss';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../helpers';

const WinsPage = (props) => {

  const winsData = useSelector((state) => state.app.winsData);
  const partners = useSelector((state) => state.app.partners);
  const games = useSelector((state) => state.app.games);
  const gamblesData = useSelector((state) => state.app.gamblesData);
  const freespinsData = useSelector((state) => state.app.freespinsData);

  return (
    <div className="WinsPage">
      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Date</th>
            <th>Bet ID</th>
            <th>Partner</th>
            <th>Player ID</th>
            <th>Game</th>
            <th>Win Amount</th>
            <th>Gambles</th>
            <th>Freespins</th>
          </tr>
        </thead>
        <tbody>
          {
            winsData.map((win, i) => {
              const partner = partners.find((p) => p.id === win.partner_id);
              const game = games.find((g) => g.key === win.game_id);

              return (
                <tr
                  key={i}
                >
                  <td>{win.time}</td>
                  <td>{win.date}</td>
                  <td>{win.bet_id === null ? `Buy Bonus Game (${freespinsData.find((f) => f.win_id === win.id)?.bet_amount * 100} ${win.currency})` : win.bet_id}</td>
                  <td>{partner && partner.name}</td>
                  <td>{win.player_id}</td>
                  <td>{game.key}</td>
                  <td>{formatNumber(win.amount_after_gamble !== null ? win.amount_after_gamble : win.amount)} {win.currency}</td>
                  <td>{gamblesData.filter((g) => g.win_id === win.id).length} {win.amount_after_gamble ? '(win)' : (win.amount_after_gamble !== null ? '(lose)' : '')}</td>
                  <td>{freespinsData.filter((f) => f.win_id === win.id).reduce((p, c) => p + c.spins_played, 0)}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default WinsPage;
